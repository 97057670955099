<template>
  <v-form
    :action="`https://forms.zohopublic.com/${type}/form/YCLeaveMessage/formperma/${guid}/htmlRecords/submit`"
    name="form"
    id="form"
    method="POST"
    accept-charset="UTF-8"
    enctype="multipart/form-data"
  >
    <input type="hidden" name="zf_referrer_name" value="" />
    <!-- To Track referrals , place the referrer name within the ' ' in the above hidden input field -->
    <input type="hidden" name="zf_redirect_url" value="" />
    <!-- To redirect to a specific page after record submission , place the respective url within the ' ' in the above hidden input field -->
    <input type="hidden" name="zc_gad" value="" />
    <!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
    <input type="text" style="display: none;" name="SingleLine2" :value="singleLine" />
    <!---------Name Starts Here---------->

    <v-select
      :color="color"
      :items="salutation"
      label="Title*"
      name="Dropdown"
    ></v-select>

    <v-text-field
      :color="color"
      label="First Name*"
      name="SingleLine"
      maxlength="255"
    ></v-text-field>

    <v-text-field
      :color="color"
      label="Last Name*"
      name="SingleLine1"
      maxlength="255"
    ></v-text-field>

    <!---------Name Ends Here---------->
    <!---------Phone Starts Here---------->
    <v-text-field
      :color="color"
      label="Phone Number*"
      name="PhoneNumber_countrycode"
      maxlength="20"
    ></v-text-field>

    <!---------Phone Ends Here---------->
    <!---------Email Starts Here---------->
    <v-text-field
      :color="color"
      label="Email Address*"
      name="Email"
      maxlength="255"
    ></v-text-field>
    <!---------Email Ends Here---------->
    <!---------Multiple Line Starts Here---------->
    <v-textarea
      :color="color"
      label="Message*"
      name="MultiLine"
      maxlength="65535"
    ></v-textarea>

    <v-btn dark :color="color" type="submit">Submit</v-btn>
  </v-form>
</template>

<script>
export default {
  name: "Zoho",
  props: {
    color: {
      type: String,
      default: "black",
    },
    type: {
      type: String,
      default: "w1viewtest02",
    },
    guid: {
      type: String,
      default: "HQOjZh508mFxes48InJqepTs_zlnNjEpcpc9WfFDEWA",
    },
    singleLine: {
      type: String,
      default: "3824628000000867230",
    },
  },
  data: () => ({
    salutation: ["Mr.", "Mrs.", "Ms.", "Dr.", "Prof."],
  }),
  created() {
    console.log(this.singleLine);
  }
};
</script>

<style lang="scss" scoped></style>
